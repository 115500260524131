/* global google */
import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import reactCSS from "reactcss";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./../../app/theme";
import contentStyles from "../auth-layout/content.styles";
import BlankProfileImage from "./../../assets/images/default-profile-pic.png";
import globalstyles from "./../../globalstyles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { library } from "@fortawesome/fontawesome-svg-core";
import CountryCodesConfig from "./../../config/country-codes";
import ScrollArea from "react-scrollbar";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

import { getAuthOperationalParameter } from "./../../store/actions/operationalParameter";
import {
  driverCreatedDone,
  registerDriver,
  searchUpcPublic,
} from "./../../store/actions/driver";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showSnackbar } from "./../../store/actions/snackbar";
import fileUpload from "./../../assets/images/file.png";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";

library.add(faUpload);

const userType = {
  1: "Chauffeur",
  2: "Rider",
};

const cartypes = [
  {
    value: "1",
    label: "Sedan",
  },
  {
    value: "2",
    label: "SUV",
  },
];

const carcolors = [
  {
    value: "black",
    label: "Black",
  },
  {
    value: "white",
    label: "White",
  },
  {
    value: "silver",
    label: "Silver",
  },
  {
    value: "red",
    label: "Red",
  },
  {
    value: "blue",
    label: "Blue",
  },
  {
    value: "green",
    label: "Green",
  },
  {
    value: "purple",
    label: "Purple",
  },
  {
    value: "gold",
    label: "Gold",
  },
];
// const languages = ['Spanish',
//    'English'
// ];

const countries = CountryCodesConfig.filter((each) => each.code === "US");

class DriverReferralSignUp extends Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.state = {
      single: null,
      displaySuggestion: false,
      upcSuggestions: [],
      selectedUpc: {},
      fetchSuggestions: false,
      labelWidth: 0,
      place: "",
      driverDetails: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        address: "",
        city: "",
        state: "",
        pin: "",
        carRegNo: "",
        carMaker: "",
        carModel: "",
        cartype: "1",
        bothCarType: false,
        rateChartType: "emerald",
        carLicensePlateNumber: "",
        carModalYear: "",
        language: ["English"],
        banner: "",
        license: "",
        insurance: "",
        vehicleColor: "",
        registrationSticker: "",
        countryCode: `${countries[0].code} (${countries[0].dial_code})`,
        limoSticker: "",
        profilePicture: "",
        location: [],
        licenseExpirationDate: "",
        insuranceExpirationDate: "",
        limoStickerExpirationDate: "",
      },
      errors: {},
      errorsUpload: {
        banner: true,
        license: false,
        insurance: false,
        registrationSticker: false,
        limoSticker: false,
      },

      haveImage: false,
      tmpAdress: "",
      selectedDate: new Date(
        new Date().setFullYear(new Date().getFullYear() - 18)
      ),
      editUserModalOpen: false,
      favModalOpen: false,
      documentModalOpen: false,
      detailsFetched: false,
      inputValue: '',
      languagePresent: [],
      languageDetailsFetched: false,
      selectedItem: [],
      displayColorPicker: false,
      color: {
        r: "241",
        g: "112",
        b: "19",
        a: "1",
      },
    };
  }
  handlePlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    let tmpPlace = JSON.stringify(place, null, 2);
    let localDriver = { ...this.state.driverDetails };
    localDriver.address = place.formatted_address;
    this.setState({
      ...this.state,
      tmpAdress: tmpPlace,
      driverDetails: localDriver,
    });
    // this.props.onPlaceChanged(place);
  };

  handleCarTypeChange = (name) => (event) => {
    let localDriverDetails = { ...this.state.driverDetails };
    localDriverDetails.carType = event.target.value;

    this.setState({
      [name]: event.target.value,
      driverDetails: localDriverDetails,
    });
  };

  handleLanguageChange = (name) => (event) => {
    let localDriverDetails = { ...this.state.driverDetails };
    let errors = { ...this.state.errors };

    errors.language = false;
    localDriverDetails.language = event.target.value;
    this.setState({
      ...this.state,
      driverDetails: localDriverDetails,
      errors,
    });
  };

  handleAutoSearchChange = (name) => (value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleChange = (event) => {
    let fields = { ...this.state.driverDetails };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "phoneNumber") {
      errors.invalidPhoneNumber = false;
    }

    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    if (event.target.name === "upcCode") {
      this.searchUPC(event.target.value);
    }

    if (event.target.name === "cartype" && event.target.value === "1") {
      fields.bothCarType = false;
      this.setState({ ...this.state, driverDetails: fields });
    }

    this.setState({ ...this.state, driverDetails: fields, errors });
  };

  documentsUploadValidation = () => {
    let uploadfields = { ...this.state.errorsUpload };
    let hasUploadErrors = false;
    if (uploadfields.banner) {
      hasUploadErrors = false;
    }
    if (!uploadfields.registrationSticker) {
      hasUploadErrors = true;
    }
    if (!uploadfields.license) {
      hasUploadErrors = true;
    }
    if (!uploadfields.limoSticker) {
      hasUploadErrors = true;
    }
    if (!uploadfields.insurance) {
      hasUploadErrors = true;
    }
    return hasUploadErrors;
  };

  driverInfoValidation = () => {
    let fields = { ...this.state.driverDetails };
    let errors = {
      ...this.state.errors,
    };
    let hasErrors = false;

    if (!fields.firstName.trim()) {
      errors.firstName = true;
      hasErrors = true;
    }
    if (!fields.lastName.trim()) {
      errors.lastName = true;
      hasErrors = true;
    }
    if (!fields.phoneNumber.trim()) {
      errors.phoneNumber = true;
      hasErrors = true;
    }
    if (
      fields.phoneNumber.trim().length &&
      fields.phoneNumber.trim().length < 10
    ) {
      errors.invalidPhoneNumber = true;
      hasErrors = true;
    }

    if (!fields.email.trim()) {
      errors.email = true;
      hasErrors = true;
    }

    if (!fields.address.trim()) {
      errors.address = true;
      hasErrors = true;
    }

    if (!fields.city.trim()) {
      errors.city = true;
      hasErrors = true;
    }

    if (!fields.state.trim()) {
      errors.state = true;
      hasErrors = true;
    }

    if (!fields.vehicleColor.trim()) {
      errors.vehicleColor = true;
      hasErrors = true;
    }

    if (!fields.pin.trim()) {
      errors.pin = true;
      hasErrors = true;
    }
    if (!fields.carModel.trim()) {
      errors.carModel = true;
      hasErrors = true;
    }
    if (!fields.carMaker.trim()) {
      errors.carMaker = true;
      hasErrors = true;
    }
    if (!fields.carLicensePlateNumber.trim()) {
      errors.carLicensePlateNumber = true;
      hasErrors = true;
    }
    if (!fields.carModalYear.trim()) {
      errors.carModalYear = true;
      hasErrors = true;
    }
    // if (!fields.carRegNo.trim()) {
    //   errors.carRegNo = true;
    //   hasErrors = true;
    // }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }

    if (!fields.language.length) {
      errors.language = true;
      hasErrors = true;
    }

    if (!fields.licenseExpirationDate) {
      errors.licenseExpirationDate = true;
      hasErrors = true;
    }

    if (!fields.insuranceExpirationDate) {
      errors.insuranceExpirationDate = true;
      hasErrors = true;
    }

    if (!fields.limoStickerExpirationDate) {
      errors.limoStickerExpirationDate = true;
      hasErrors = true;
    }

    if (!fields.banner) {
      errors.banner = true;
      hasErrors = true;
    }

    // console.log("hasErrors ", hasErrors);
    // console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  searchUPC = (searchData) => {
    this.props.searchUpc(searchData);
    // if (searchData.trim().length) {
    this.setState({
      ...this.state,
      displaySuggestion: true,
      fetchSuggestions: false,
    });
    // }
  };

  searchUPCPublic = (code, userId) => {
    this.props
      .searchUpc(code, userId)
      .then((response) => {
        console.log("API Response:", response);

        if (response.payload.data.length > 0) {
          const referrer = response.payload.data[0];
          this.setState((prev) => ({
            ...prev,
            selectedUpc: {
              id: referrer._id,
              userType: referrer.userType,
            },
          }));
        } else {
          showSnackbar("Invalid UPC code", "error");
        }
      })
      .catch((error) => {
        console.error("Error verifying UPC code:", error);
        showSnackbar("Failed to verify UPC code", "error");
      });
  };

  verifyUpcCode = async (code) => {
    this.searchUPCPublic(code, this.state.driverDetails.id);
  };

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      { types: ["geocode"] }
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
    this.props.getAuthOperationalParameter();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      this.setState(
        (prevState) => ({
          driverDetails: {
            ...prevState.driverDetails,
            upcCode: code,
          },
        }),
        () => {
          // After state update, call the verifyUpcCode method
          this.verifyUpcCode(code);
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (ReactDOM.findDOMNode(this.InputLabelRef)) {
      this.setState({
        ...this.state,
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }

    if (
      this.props.data.driverReducer.upcData.length &&
      !this.state.fetchSuggestions
    ) {
      this.setState({
        ...this.state,
        upcSuggestions: this.props.data.driverReducer.upcData,
        fetchSuggestions: true,
        displaySuggestion: true,
      });
    }
    if (
      !this.props.data.driverReducer.upcData.length &&
      this.state.fetchSuggestions
    ) {
      this.setState({
        ...this.state,
        upcSuggestions: this.props.data.driverReducer.upcData,
        fetchSuggestions: false,
        displaySuggestion: true,
      });
    }
    if (
      Object.keys(
        this.props.data.operationalParameterData.operationalAuthParameterDetails
      ).length &&
      !this.state.languageDetailsFetched
    ) {
      let localParameter = [...this.state.languagePresent];
      localParameter = [
        ...this.props.data.operationalParameterData
          .operationalAuthParameterDetails.languagesSpoken,
      ];

      this.setState({
        ...this.state,
        languagePresent: localParameter,
        languageDetailsFetched: true,
      });
    }


    if (this.props.data.driverReducer.driverCreated) {
      
      this.props.driverCreatedDone();
      window.location.href = "https://www.urpc.us";
    }

  }

  selectUpc = (suggestion) => {
    let localDriverData = { ...this.state.driverDetails };
    localDriverData.upcCode = suggestion.upcBucketInfo.upcCode;
    this.setState({
      ...this.state,
      selectedUpc: { ...suggestion },
      displaySuggestion: false,
      driverDetails: localDriverData,
    });
  };

  componentWillUnmount() {}

  saveHandler = () => {
    if (!this.driverInfoValidation()) {
      if (this.documentsUploadValidation()) {
        this.props.showSnackbar("Please upload all documents", "error");
        return;
      } else {
        let requestData = { ...this.state.driverDetails };

        if (!this.state.tmpAdress) {
          this.props.showSnackbar(
            "Please enter adress from suggestion box",
            "error"
          );
          return;
        }
        let place = JSON.parse(this.state.tmpAdress);
        let latitude = place.geometry.location.lat;
        let longitude = place.geometry.location.lng;

        let tmpdate = new Date(this.state.selectedDate);

        let payload = {
          firstName: requestData.firstName,
          lastName: requestData.lastName,
          email: requestData.email,
          countryCode: requestData.countryCode.split(" (")[0],
          number: requestData.phoneNumber,
          dob: tmpdate.toISOString(),
          profilePicture: requestData.profilePicture,
          languageSpoken: JSON.stringify(requestData.language),
          location: JSON.stringify([longitude, latitude]),
          line: place.formatted_address,
          state: requestData.state,
          zip: requestData.pin,
          city: requestData.city,
          vehicleType: requestData.cartype,
          vehicleMake: requestData.carMaker,
          vehicleModel: requestData.carModel,
          vehicleColor: requestData.vehicleColor,
          vehicleRegistrationNo: new Date().toLocaleTimeString("it-IT"),
          license: requestData.license,
          banner: requestData.banner,
          insurance: requestData.insurance,
          registrationSticker: requestData.registrationSticker,
          limoSticker: requestData.limoSticker,
          licenseExpirationDate:
            requestData.licenseExpirationDate.toISOString(),
          insuranceExpirationDate:
            requestData.insuranceExpirationDate.toISOString(),
          limoStickerExpirationDate:
            requestData.limoStickerExpirationDate.toISOString(),
          carLicensePlateNumber: requestData.carLicensePlateNumber,
          vehicleModalYear: Number(requestData.carModalYear),
          both: requestData.bothCarType === true ? 1 : 0,
          rateChartType: requestData.rateChartType,
        };

        console.log("this.state.selectedUpc ", this.state.selectedUpc);
        if (requestData.upcCode.trim().length) {
          if (
            !Object.keys(this.state.selectedUpc).length ||
            (Object.keys(this.state.selectedUpc).length &&
              this.state.selectedUpc.upcBucketInfo.upcCode !==
                requestData.upcCode)
          ) {
            this.props.showSnackbar("Invalid UPC code", "error");
            return;
          }
        }

        if (Object.keys(this.state.selectedUpc).length) {
          payload.upcCodeReferredBy = this.state.selectedUpc._id;
          payload.upcCodeReferredByType = this.state.selectedUpc.userType;
        }

        let formData = new FormData();
        for (let name in payload) {
          formData.append(name, payload[name]);
        }
        this.props.addDriver(formData);
      }
    }
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handleExpiryDateChange = (key) => (date) => {
    this.setState({
      driverDetails: {
        ...this.state.driverDetails,
        [key]: date,
      },
      errors: {
        ...this.state.errors,
        [key]: false,
      },
    });
  };

  handleColorPicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleColorPickerClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleColorChange = (event) => {
    let tmpDetail = { ...this.state.driverDetails };
    tmpDetail.vehicleColor = event.target.value;
    this.setState({
      ...this.state,
      driverDetails: tmpDetail,
      errors: {
        ...this.state.errors,
        ["vehicleColor"]: false,
      },
    });
  };

  handleBothVehicleType = (val) => {
    let tmpDetail = { ...this.state.driverDetails };
    tmpDetail.bothCarType = val;
    this.setState({ ...this.state, driverDetails: tmpDetail });
  };

  handleRateTypeChange = (event) => {
    let tmpDetail = { ...this.state.driverDetails };
    tmpDetail.rateChartType = event.target.value;
    this.setState({ ...this.state, driverDetails: tmpDetail });
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  };
  uploadLogo = (e) => {
    if (
      e.target.files &&
      e.target.files.length &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpg" &&
      e.target.files[0].type !== "image/jpeg"
    ) {
      this.props.showSnackbar("Profile image must be of jpg/png", "error");
      return;
    }

    let localDriverDataProfile = { ...this.state.driverDetails };
    localDriverDataProfile.profilePicture = e.target.files[0];
    this.setState({ ...this.state, driverDetails: localDriverDataProfile });
  };
  uploadRegsitration = (e) => {
    if (
      e.target.files &&
      e.target.files.length &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpg" &&
      e.target.files[0].type !== "image/jpeg"
    ) {
      this.props.showSnackbar(
        "Registration image must be of of jpg/png",
        "error"
      );
      return;
    }
    let localDocuments = { ...this.state.errorsUpload };
    localDocuments.registrationSticker = true;
    let localDriverDataReg = { ...this.state.driverDetails };
    localDriverDataReg.registrationSticker = e.target.files[0];
    this.setState({
      ...this.state,
      driverDetails: localDriverDataReg,
      errorsUpload: localDocuments,
    });
  };
  uploadLimoSticker = (e) => {
    if (
      e.target.files &&
      e.target.files.length &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpg" &&
      e.target.files[0].type !== "image/jpeg"
    ) {
      this.props.showSnackbar(
        "Limosticker image must be of of jpg/png",
        "error"
      );
      return;
    }
    let localDocuments = { ...this.state.errorsUpload };
    localDocuments.limoSticker = true;
    let localDriverDataLimo = { ...this.state.driverDetails };
    localDriverDataLimo.limoSticker = e.target.files[0];
    this.setState({
      ...this.state,
      driverDetails: localDriverDataLimo,
      errorsUpload: localDocuments,
    });
  };
  uploadInsurance = (e) => {
    if (
      e.target.files &&
      e.target.files.length &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpg" &&
      e.target.files[0].type !== "image/jpeg"
    ) {
      this.props.showSnackbar("Insurance image must be of of jpg/png", "error");
      return;
    }
    let localDocuments = { ...this.state.errorsUpload };
    localDocuments.insurance = true;
    let localDriverDataInsurance = { ...this.state.driverDetails };
    localDriverDataInsurance.insurance = e.target.files[0];
    this.setState({
      ...this.state,
      driverDetails: localDriverDataInsurance,
      errorsUpload: localDocuments,
    });
  };
  uploadDrivingLicence = (e) => {
    if (
      e.target.files &&
      e.target.files.length &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpg" &&
      e.target.files[0].type !== "image/jpeg"
    ) {
      this.props.showSnackbar(
        "Driving license image must be of of jpg/png",
        "error"
      );
      return;
    }
    let localDocuments = { ...this.state.errorsUpload };
    localDocuments.license = true;
    let localDriverDataLicence = { ...this.state.driverDetails };
    localDriverDataLicence.license = e.target.files[0];
    this.setState({
      ...this.state,
      driverDetails: localDriverDataLicence,
      errorsUpload: localDocuments,
    });
  };

  uploadBanner = (e) => {
    if (
      e.target.files &&
      e.target.files.length &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpg" &&
      e.target.files[0].type !== "image/jpeg"
    ) {
      this.props.showSnackbar(
        "Driving license image must be of of jpg/png",
        "error"
      );
      return;
    }
    let localDocuments = { ...this.state.errorsUpload };
    localDocuments.banner = false;
    let localDriverDataBanner = { ...this.state.driverDetails };
    localDriverDataBanner.banner = e.target.files[0];
    this.setState({
      ...this.state,
      driverDetails: localDriverDataBanner,
      errorsUpload: localDocuments,
    });
  };

  render() {
    const { classes } = this.props;
    const { inputValue, selectedItem } = this.state;
    const { selectedDate } = this.state;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const colorpickerStyles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <Grid container>
        <Grid item style={{ display:'flex', flexDirection: 'column', margin: '0 auto' }}>
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              <Link to="/login">
                <img src={Logo} alt="logo" style={{height: 64}}/>
              </Link>
            </Typography>
            <Typography component="p" style={{ textAlign: "center", paddingLeft: 16 }}>
              <span className={classes.pageHeader}>
              {this.props.data.literalsReducer.Register.Driver}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Paper>
                <Grid item xs={12} sm={12} className={classes.rightPaper}>
                  <Grid item xs={12} sm={12} className={classes.descSection}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12}>
                        <div
                          className={classes.profileImageUploaderContainerWrap}
                        >
                          <div className={classes.profileImagePlaceholder}>
                            {this.state.driverDetails.profilePicture ? (
                              typeof this.state.driverDetails.profilePicture ===
                              "object" ? (
                                <div
                                  style={{
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    width: "100%",
                                    height: "100%",
                                    backgroundImage: `url( ${window.URL.createObjectURL(
                                      this.state.driverDetails.profilePicture
                                    )} )`,
                                  }}
                                ></div>
                              ) : (
                                <img
                                  src={this.state.driverDetails.profilePicture}
                                  alt="image"
                                  style={{ width: "100%" }}
                                />
                              )
                            ) : (
                              <img
                                src={BlankProfileImage}
                                alt="no-menu-image"
                                style={{ width: "100%" }}
                              />
                            )}
                          </div>
                          <div
                            className={classes.profileImageUploaderContainer}
                          >
                            <div
                              className={classes.uploadImageButtonFile}
                              onClick={(e) => {
                                this.refs["file-upload"].click();
                              }}
                            >
                              <input
                                ref={"file-upload"}
                                type="file"
                                style={{
                                  display: "none",
                                }}
                                onChange={this.uploadLogo}
                              />
                              <FontAwesomeIcon
                                icon={faUpload}
                                style={{ fontSize: "30px", color: "#fff" }}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <h3 style={{ marginBottom: "0" }}>Personal Details</h3>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          {this.props.data.literalsReducer.AddRider.Name}
                        </p>
                        <Grid container spacing={16}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="driver-first-name"
                              label={
                                this.props.data.literalsReducer.AddRider
                                  .FirstName
                              }
                              variant="outlined"
                              className={classNames(
                                classes.textField,
                                classes.halfField
                              )}
                              name="firstName"
                              onChange={this.handleChange}
                              value={this.state.driverDetails.firstName}
                              error={this.state.errors.firstName}
                            />
                            {this.state.errors.firstName ? (
                              <FormHelperText id="validation-error2">
                                {
                                  this.props.data.literalsReducer
                                    .ValidationErrors.Required
                                }
                              </FormHelperText>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="rider-last-name"
                              label={
                                this.props.data.literalsReducer.AddRider
                                  .LastName
                              }
                              variant="outlined"
                              className={classNames(
                                classes.textField,
                                classes.halfField
                              )}
                              name="lastName"
                              value={this.state.driverDetails.lastName || ""}
                              onChange={this.handleChange}
                              error={this.state.errors.lastName}
                            />
                            {this.state.errors.lastName ? (
                              <FormHelperText id="validation-error2">
                                {
                                  this.props.data.literalsReducer
                                    .ValidationErrors.Required
                                }
                              </FormHelperText>
                            ) : null}
                          </Grid>
                        </Grid>
                        <p className={classes.description}>
                          {this.props.data.literalsReducer.AddRider.PhoneNo}
                        </p>
                        <div className={classes.inlineField}>
                          <TextField
                            id="select-country"
                            select
                            variant="outlined"
                            name="countryCode"
                            className={classNames(
                              classes.textField,
                              classes.countrySelector
                            )}
                            value={this.state.driverDetails.countryCode}
                            onChange={this.handleChange}
                            SelectProps={{
                              native: true,
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                          >
                            {countries.map((option, index) => (
                              <option
                                key={index}
                                value={`${option.code} (${option.dial_code})`}
                              >
                                {`${option.code} (${option.dial_code})`}
                              </option>
                            ))}
                          </TextField>
                          <TextField
                            id="rider-phone-no"
                            label={
                              this.props.data.literalsReducer.AddRider.PhoneNo
                            }
                            variant="outlined"
                            fullWidth
                            className={classNames(
                              classes.textField,
                              classes.phoneNumber
                            )}
                            name="phoneNumber"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            inputProps={{
                              minLength: 10,
                              maxLength: 10,
                            }}
                            value={this.state.driverDetails.phoneNumber}
                            onChange={this.handleChange}
                            error={
                              this.state.errors.phoneNumber ||
                              this.state.errors.invalidPhoneNumber
                            }
                          />
                          {this.state.errors.phoneNumber ? (
                            <FormHelperText
                              id="validation-error2"
                              style={{ marginLeft: "33%" }}
                            >
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                          {this.state.errors.invalidPhoneNumber &&
                          !this.state.errors.phoneNumber ? (
                            <FormHelperText
                              id="validation-error2"
                              style={{ marginLeft: "33%" }}
                            >
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .InvalidPhoneNumber
                              }
                            </FormHelperText>
                          ) : null}
                        </div>
                        <p className={classes.description}>
                          {this.props.data.literalsReducer.AddRider.Email}
                        </p>
                        <TextField
                          id="rider-email"
                          label={this.props.data.literalsReducer.AddRider.Email}
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          type="email"
                          className={classes.textField}
                          value={this.state.driverDetails.email}
                          name="email"
                          error={
                            this.state.errors.email ||
                            this.state.errors.emailInvalid
                          }
                        />
                        {this.state.errors.email ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                        {!this.state.errors.email &&
                        this.state.errors.emailInvalid ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .InvalidEmail
                            }
                          </FormHelperText>
                        ) : null}
                        <p className={classes.description}>
                          {this.props.data.literalsReducer.DriverDetails.Dob}
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            className={classes.textField}
                            variant="outlined"
                            format="dd-MM-Y"
                            fullWidth
                            name="dob"
                            maxDate={
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 18
                                )
                              )
                            }
                            value={selectedDate}
                            onChange={this.handleDateChange}
                          />
                        </MuiPickersUtilsProvider>
                        <p className={classes.description}>
                          {
                            this.props.data.literalsReducer.DriverDetails
                              .Language
                          }
                        </p>
                        {this.state.errors.language ? (
                          <Select
                            multiple
                            style={{
                              border: "1px solid #f50057",
                              borderRadius: "5px",
                              padding: "10px",
                              width: "97%",
                            }}
                            value={this.state.driverDetails.language}
                            onChange={this.handleLanguageChange("language")}
                            input={<Input id="select-multiple" />}
                            MenuProps={MenuProps}
                            error={this.state.errors.language}
                          >
                            {this.state.languagePresent.map((name, index) => (
                              <MenuItem key={index} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            multiple
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              padding: "10px",
                              width: "97%",
                            }}
                            value={this.state.driverDetails.language}
                            onChange={this.handleLanguageChange("language")}
                            input={<Input id="select-multiple" />}
                            MenuProps={MenuProps}
                            error={this.state.errors.language}
                          >
                            {this.state.languagePresent.map((name, index) => (
                              <MenuItem key={index} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}

                        {this.state.errors.language ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>Address</p>
                        <div className={classes.addressField}>
                          {/*  */}
                          {/* <Input
                      inputComponent={this.renderPlace}
                      
                      label="Address"
                      variant="outlined"
                      fullWidth
                      name="address"
                      // error={
                      //   this.state.errors.address
                      // }
                     /> */}
                          <input
                            ref={this.autocompleteInput}
                            id="autocomplete"
                            label="Address"
                            variant="outlined"
                            fullWidth
                            name="address"
                            error={this.state.errors.address}
                            value={this.state.driverDetails.address}
                            onChange={this.handleChange}
                          />

                          {this.state.errors.address ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                        </div>
                        <p className={classes.description}>
                          {
                            this.props.data.literalsReducer.DriverDetails
                              .AddressCity
                          }
                        </p>
                        <TextField
                          id="rider-address"
                          label={
                            this.props.data.literalsReducer.DriverDetails
                              .AddressCity
                          }
                          variant="outlined"
                          onChange={this.handleChange}
                          fullWidth
                          className={classes.textField}
                          name="city"
                          error={this.state.errors.city}
                        />
                        {this.state.errors.city ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                        <p className={classes.description}>
                          {
                            this.props.data.literalsReducer.DriverDetails
                              .AddressState
                          }
                        </p>
                        <TextField
                          id="rider-address"
                          onChange={this.handleChange}
                          label={
                            this.props.data.literalsReducer.DriverDetails
                              .AddressState
                          }
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          name="state"
                          error={this.state.errors.state}
                        />
                        {this.state.errors.state ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                        <p className={classes.description}>
                          {
                            this.props.data.literalsReducer.DriverDetails
                              .AddressPin
                          }
                        </p>
                        <TextField
                          id="rider-address"
                          label={
                            this.props.data.literalsReducer.DriverDetails
                              .AddressPin
                          }
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          className={classes.textField}
                          name="pin"
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          error={this.state.errors.pin}
                        />
                        {this.state.errors.pin ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                        <p className={classes.description}>
                          {
                            this.props.data.literalsReducer.AddRider
                              .AttachUpcCode
                          }
                        </p>
                        <TextField
                          id="driver-upc-code"
                          label={
                            this.props.data.literalsReducer.AddRider
                              .AttachUpcCode
                          }
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          name="upcCode"
                          value={this.state.driverDetails.upcCode || ""}
                          onChange={this.handleChange}
                        />
                        {this.props.data.driverReducer.upcData.length &&
                        this.state.displaySuggestion ? (
                          <div className={classes.suggestionList}>
                            <ScrollArea
                              speed={0.8}
                              className={classes.scrollContent}
                              contentClassName="content"
                              horizontal={false}
                              style={{ minHeight: "110px" }}
                            >
                              {this.props.data.driverReducer.upcData.map(
                                (suggestion, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={classes.suggestionItem}
                                      onClick={() => this.selectUpc(suggestion)}
                                    >
                                      {suggestion.personalInfo.fullName} (
                                      {userType[suggestion.userType]}) -{" "}
                                      {suggestion.upcBucketInfo.upcCode}
                                    </div>
                                  );
                                }
                              )}
                            </ScrollArea>
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h3>Car Details</h3>
                        <Grid container spacing={16}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Paper
                              square={true}
                              elevation={0}
                              className={classes.bannerfileUploadContainer}
                            >
                              {this.state.driverDetails.banner ? (
                                <>
                                  <Button
                                    variant="contained"
                                    onClick={(e) => {
                                      this.refs["file-upload-banner"].click();
                                    }}
                                    className={classNames(
                                      classes.activeGoButton,
                                      classes.reuploadButton
                                    )}
                                  >
                                    Choose File
                                  </Button>
                                  {typeof this.state.driverDetails.banner ===
                                  "object" ? (
                                    <div
                                      style={{
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        width: "100%",
                                        height: "300px",
                                        backgroundImage: `url( ${window.URL.createObjectURL(
                                          this.state.driverDetails.banner
                                        )} )`,
                                      }}
                                    ></div>
                                  ) : (
                                    <img
                                      src={this.state.driverDetails.banner}
                                      alt="image"
                                      style={{ width: "100%" }}
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  <img
                                    src={fileUpload}
                                    width="50dp"
                                    style={{ marginBottom: "10px" }}
                                  />
                                  <Typography
                                    variant="subtitle2"
                                    component="h4"
                                  >
                                    Upload Vehicle Banner Images here
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    component="h4"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.banner
                                      ? "This field is required."
                                      : ""}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    component="h4"
                                    className={classes.PageDescription}
                                  >
                                    Files supported: JPG, PNG, JPEG.
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    onClick={(e) => {
                                      this.refs["file-upload-banner"].click();
                                    }}
                                    className={classes.activeGoButton}
                                  >
                                    Choose File
                                  </Button>
                                </>
                              )}
                              <input
                                ref={"file-upload-banner"}
                                type="file"
                                style={{
                                  display: "none",
                                }}
                                onChange={this.uploadBanner}
                              />
                            </Paper>
                          </Grid>

                          {/* -------------------------- */}

                          <Grid item xs={12} sm={6}>
                            <p className={classes.description}>
                              {
                                this.props.data.literalsReducer.DriverDetails
                                  .CarType
                              }
                            </p>
                            <TextField
                              id="select-car-type"
                              select
                              variant="outlined"
                              fullWidth
                              name="cartype"
                              className={classes.textField}
                              value={this.state.driverDetails.cartype}
                              onChange={this.handleChange}
                              SelectProps={{
                                native: true,
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}
                            >
                              {cartypes.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </TextField>

                            {/* <Checkbox
                            // checked={type.selected}
                            // onChange={() =>
                            //   this.handleRoleSelectionChange(index)
                            // }
                            // value={type.value}
                            // color="primary"
                            /> */}

                            <Grid container spacing={8}>
                              <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                  style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                  }}
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.driverDetails.bothCarType
                                      }
                                      name="bothCarType"
                                      disabled={
                                        this.state.driverDetails.cartype === "1"
                                      }
                                      onChange={(e) =>
                                        this.handleBothVehicleType(
                                          !this.state.driverDetails.bothCarType
                                        )
                                      }
                                    />
                                  }
                                  label="Both Car Type"
                                />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    className={classes.rateWrapper}
                                    value={
                                      this.state.driverDetails.rateChartType
                                    }
                                    onChange={this.handleRateTypeChange}
                                  >
                                    <FormControlLabel
                                      className={classes.radioText}
                                      value="emerald"
                                      control={<Radio />}
                                      label="Emerald"
                                    />
                                    <FormControlLabel
                                      className={classes.radioText}
                                      value="sapphire"
                                      control={<Radio />}
                                      label="Sapphire"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                              {/* <Grid item xs={12} sm={4} className={classes.colorSelector}>
                                <p className={classes.description}>
                                  {this.props.data.literalsReducer.DriverDetails.CarColor}
                                </p>
                                <div style={{ marginLeft: "20px" }}>
                                  <div style={colorpickerStyles.swatch} onClick={this.handleColorPicker}>
                                    <div style={colorpickerStyles.color} />
                                  </div>
                                  {this.state.displayColorPicker ? <div style={colorpickerStyles.popover}>
                                    <div style={colorpickerStyles.cover} onClick={this.handleColorPickerClose} />
                                    <SketchPicker color={this.state.color} onChange={this.handleColorChange} />
                                  </div> : null}
                                </div>
                              </Grid> */}
                              <p className={classes.description}>
                                {
                                  this.props.data.literalsReducer.DriverDetails
                                    .CarColor
                                }
                              </p>
                              <TextField
                                id="select-car-color"
                                select
                                variant="outlined"
                                fullWidth
                                name="vehicleColor"
                                className={classes.textField}
                                value={this.state.driverDetails.vehicleColor}
                                onChange={this.handleColorChange}
                                style={{ marginBottom: "30px !important" }}
                                SelectProps={{
                                  native: true,
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                              >
                                <option value="" disabled>
                                  Select Color
                                </option>
                                {carcolors.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </TextField>
                              {this.state.errors.vehicleColor ? (
                                <FormHelperText id="validation-error2">
                                  {
                                    this.props.data.literalsReducer
                                      .ValidationErrors.Required
                                  }
                                </FormHelperText>
                              ) : null}
                            </Grid>

                            <Grid item xs={12} sm={12}>
                              <p className={classes.description}>
                                {
                                  this.props.data.literalsReducer.DriverDetails
                                    .carLicensePlateNumber
                                }
                              </p>
                              <TextField
                                id="carLicensePlateNumber"
                                label={
                                  this.props.data.literalsReducer.DriverDetails
                                    .carLicensePlateNumber
                                }
                                variant="outlined"
                                fullWidth
                                className={classes.textField}
                                name="carLicensePlateNumber"
                                onChange={this.handleChange}
                                error={this.state.errors.carLicensePlateNumber}
                              />
                              {this.state.errors.carLicensePlateNumber ? (
                                <FormHelperText id="validation-error2">
                                  {
                                    this.props.data.literalsReducer
                                      .ValidationErrors.Required
                                  }
                                </FormHelperText>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container spacing={16}>
                          {/* <Grid item xs={12} sm={4}>
                            <p className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.CarType}
                            </p>
                            <TextField
                              id="select-car-type"
                              select
                              variant="outlined"
                              fullWidth
                              name="cartype"
                              className={classes.textField}
                              value={this.state.driverDetails.cartype}
                              onChange={this.handleChange}
                              SelectProps={{
                                native: true,
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}
                            >
                              {cartypes.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </TextField>
                          </Grid> */}
                          <Grid item xs={12} sm={6}>
                            <p className={classes.description}>
                              {
                                this.props.data.literalsReducer.DriverDetails
                                  .CarMaker
                              }
                            </p>
                            <TextField
                              id="car-maker"
                              label={
                                this.props.data.literalsReducer.DriverDetails
                                  .CarMaker
                              }
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                              name="carMaker"
                              onChange={this.handleChange}
                              error={this.state.errors.carMaker}
                            />
                            {this.state.errors.carMaker ? (
                              <FormHelperText id="validation-error2">
                                {
                                  this.props.data.literalsReducer
                                    .ValidationErrors.Required
                                }
                              </FormHelperText>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <p className={classes.description}>
                              {
                                this.props.data.literalsReducer.DriverDetails
                                  .CarModel
                              }
                            </p>
                            <TextField
                              id="car-maker"
                              label={
                                this.props.data.literalsReducer.DriverDetails
                                  .CarModel
                              }
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                              name="carModel"
                              onChange={this.handleChange}
                              error={this.state.errors.carModel}
                            />
                            {this.state.errors.carModel ? (
                              <FormHelperText id="validation-error2">
                                {
                                  this.props.data.literalsReducer
                                    .ValidationErrors.Required
                                }
                              </FormHelperText>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <p className={classes.description}>
                              {
                                this.props.data.literalsReducer.DriverDetails
                                  .carModalYear
                              }
                            </p>
                            <TextField
                              id="carModalYear"
                              label={
                                this.props.data.literalsReducer.DriverDetails
                                  .carModalYear
                              }
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                              name="carModalYear"
                              onChange={this.handleChange}
                              error={this.state.errors.carModalYear}
                            />
                            {this.state.errors.carModalYear ? (
                              <FormHelperText id="validation-error2">
                                {
                                  this.props.data.literalsReducer
                                    .ValidationErrors.Required
                                }
                              </FormHelperText>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <h3>Documents</h3>
                        <div className={classes.description}>
                          <Grid
                            container
                            spacing={16}
                            className={classNames(
                              classes.documentImage,
                              classes.editableDocumentImage
                            )}
                          >
                            {/* Chauffeur's license */}
                            <Grid item xs={12} sm={6} md={4}>
                              <Paper
                                elevation={1}
                                fullWidth
                                className={classes.docCard}
                              >
                                <p className={classes.description}>
                                  Chauffeur's License expiry date
                                </p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    className={classes.textField}
                                    variant="outlined"
                                    format="dd-MM-Y"
                                    fullWidth
                                    placeholder="License expiry date"
                                    name="licenseExpirationDate"
                                    minDate={new Date()}
                                    value={
                                      this.state.driverDetails
                                        .licenseExpirationDate || null
                                    }
                                    onChange={this.handleExpiryDateChange(
                                      "licenseExpirationDate"
                                    )}
                                  />
                                </MuiPickersUtilsProvider>
                                {this.state.errors.licenseExpirationDate ? (
                                  <FormHelperText id="validation-error2">
                                    {
                                      this.props.data.literalsReducer
                                        .ValidationErrors.Required
                                    }
                                  </FormHelperText>
                                ) : null}
                                <Paper
                                  square={true}
                                  elevation={0}
                                  className={classes.fileUploadContainer}
                                >
                                  {this.state.driverDetails.license ? (
                                    <>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          this.refs[
                                            "file-upload-licence"
                                          ].click();
                                        }}
                                        className={classNames(
                                          classes.activeGoButton,
                                          classes.reuploadButton
                                        )}
                                      >
                                        Choose File
                                      </Button>
                                      {typeof this.state.driverDetails
                                        .license === "object" ? (
                                        <div
                                          style={{
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            width: "100%",
                                            height: "173px",
                                            backgroundImage: `url( ${window.URL.createObjectURL(
                                              this.state.driverDetails.license
                                            )} )`,
                                          }}
                                        ></div>
                                      ) : (
                                        <img
                                          src={this.state.driverDetails.license}
                                          alt="image"
                                          style={{ width: "100%" }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <img src={fileUpload} width="50dp" />
                                      <Typography
                                        variant="subtitle2"
                                        component="h4"
                                      >
                                        Upload files here
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        component="h4"
                                        className={classes.PageDescription}
                                      >
                                        Files supported: JPG, PNG, JPEG.
                                      </Typography>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          this.refs[
                                            "file-upload-licence"
                                          ].click();
                                        }}
                                        className={classes.activeGoButton}
                                      >
                                        Choose File
                                      </Button>
                                    </>
                                  )}
                                  <input
                                    ref={"file-upload-licence"}
                                    type="file"
                                    style={{
                                      display: "none",
                                    }}
                                    onChange={this.uploadDrivingLicence}
                                  />
                                </Paper>
                              </Paper>
                            </Grid>

                            {/* Insurance */}
                            <Grid item xs={12} sm={6} md={4}>
                              <Paper
                                elevation={1}
                                fullWidth
                                className={classes.docCard}
                              >
                                <p className={classes.description}>
                                  Insurance expiry date
                                </p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    className={classes.textField}
                                    variant="outlined"
                                    format="dd-MM-Y"
                                    fullWidth
                                    name="insuranceExpirationDate"
                                    minDate={new Date()}
                                    placeholder="Insurance expiry date"
                                    value={
                                      this.state.driverDetails
                                        .insuranceExpirationDate || null
                                    }
                                    onChange={this.handleExpiryDateChange(
                                      "insuranceExpirationDate"
                                    )}
                                  />
                                </MuiPickersUtilsProvider>
                                {this.state.errors.insuranceExpirationDate ? (
                                  <FormHelperText id="validation-error2">
                                    {
                                      this.props.data.literalsReducer
                                        .ValidationErrors.Required
                                    }
                                  </FormHelperText>
                                ) : null}

                                <Paper
                                  square={true}
                                  elevation={0}
                                  className={classes.fileUploadContainer}
                                >
                                  {this.state.driverDetails.insurance ? (
                                    <>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          this.refs[
                                            "file-upload-insurance"
                                          ].click();
                                        }}
                                        className={classNames(
                                          classes.activeGoButton,
                                          classes.reuploadButton
                                        )}
                                      >
                                        Choose File
                                      </Button>
                                      {typeof this.state.driverDetails
                                        .insurance === "object" ? (
                                        <div
                                          style={{
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            width: "100%",
                                            height: "173px",
                                            backgroundImage: `url( ${window.URL.createObjectURL(
                                              this.state.driverDetails.insurance
                                            )} )`,
                                          }}
                                        ></div>
                                      ) : (
                                        <img
                                          src={
                                            this.state.driverDetails.insurance
                                          }
                                          alt="image"
                                          style={{ width: "100%" }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <img src={fileUpload} width="50dp" />
                                      <Typography
                                        variant="subtitle2"
                                        component="h4"
                                      >
                                        Upload files here
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        component="h4"
                                        className={classes.PageDescription}
                                      >
                                        Files supported: JPG, PNG, JPEG.
                                      </Typography>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          this.refs[
                                            "file-upload-insurance"
                                          ].click();
                                        }}
                                        className={classes.activeGoButton}
                                      >
                                        Choose File
                                      </Button>
                                    </>
                                  )}
                                  <input
                                    ref={"file-upload-insurance"}
                                    type="file"
                                    style={{
                                      display: "none",
                                    }}
                                    onChange={this.uploadInsurance}
                                  />
                                </Paper>
                              </Paper>
                            </Grid>

                            {/* ARA Document */}
                            <Grid item xs={12} sm={6} md={4}>
                              <Paper
                                elevation={1}
                                fullWidth
                                className={classes.docCard}
                              >
                                <p className={classes.description}>
                                  ARA Document expiry date
                                </p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    className={classes.textField}
                                    variant="outlined"
                                    format="dd-MM-Y"
                                    fullWidth
                                    name="limoStickerExpirationDate"
                                    minDate={new Date()}
                                    placeholder="ARA Document expiry date"
                                    value={
                                      this.state.driverDetails
                                        .limoStickerExpirationDate || null
                                    }
                                    onChange={this.handleExpiryDateChange(
                                      "limoStickerExpirationDate"
                                    )}
                                  />
                                </MuiPickersUtilsProvider>
                                {this.state.errors.limoStickerExpirationDate ? (
                                  <FormHelperText id="validation-error2">
                                    {
                                      this.props.data.literalsReducer
                                        .ValidationErrors.Required
                                    }
                                  </FormHelperText>
                                ) : null}
                                <Paper
                                  square={true}
                                  elevation={0}
                                  className={classes.fileUploadContainer}
                                >
                                  {this.state.driverDetails.limoSticker ? (
                                    <>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          this.refs["file-upload-limo"].click();
                                        }}
                                        className={classNames(
                                          classes.activeGoButton,
                                          classes.reuploadButton
                                        )}
                                      >
                                        Choose File
                                      </Button>
                                      {typeof this.state.driverDetails
                                        .limoSticker === "object" ? (
                                        <div
                                          style={{
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            width: "100%",
                                            height: "173px",
                                            backgroundImage: `url( ${window.URL.createObjectURL(
                                              this.state.driverDetails
                                                .limoSticker
                                            )} )`,
                                          }}
                                        ></div>
                                      ) : (
                                        <img
                                          src={
                                            this.state.driverDetails.limoSticker
                                          }
                                          alt="image"
                                          style={{ width: "100%" }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <img src={fileUpload} width="50dp" />
                                      <Typography
                                        variant="subtitle2"
                                        component="h4"
                                      >
                                        Upload files here
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        component="h4"
                                        className={classes.PageDescription}
                                      >
                                        Files supported: JPG, PNG, JPEG.
                                      </Typography>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          this.refs["file-upload-limo"].click();
                                        }}
                                        className={classes.activeGoButton}
                                      >
                                        Choose File
                                      </Button>
                                    </>
                                  )}
                                  <input
                                    ref={"file-upload-limo"}
                                    type="file"
                                    style={{
                                      display: "none",
                                    }}
                                    onChange={this.uploadLimoSticker}
                                  />
                                </Paper>
                              </Paper>
                            </Grid>

                            {/* Registration Sticker */}
                            <Grid item xs={12} sm={6} md={4}>
                              <Paper
                                elevation={1}
                                fullWidth
                                className={classes.docCard}
                              >
                                <p className={classes.description}>
                                  Registration Sticker
                                </p>
                                <Paper
                                  square={true}
                                  elevation={0}
                                  className={classes.fileUploadContainer}
                                >
                                  {this.state.driverDetails
                                    .registrationSticker ? (
                                    <>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          this.refs["file-upload-reg"].click();
                                        }}
                                        className={classNames(
                                          classes.activeGoButton,
                                          classes.reuploadButton
                                        )}
                                      >
                                        Choose File
                                      </Button>
                                      {typeof this.state.driverDetails
                                        .registrationSticker === "object" ? (
                                        <div
                                          style={{
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            width: "100%",
                                            height: "173px",
                                            backgroundImage: `url( ${window.URL.createObjectURL(
                                              this.state.driverDetails
                                                .registrationSticker
                                            )} )`,
                                          }}
                                        ></div>
                                      ) : (
                                        <img
                                          src={
                                            this.state.driverDetails
                                              .registrationSticker
                                          }
                                          alt="image"
                                          style={{ width: "100%" }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <img src={fileUpload} width="50dp" />
                                      <Typography
                                        variant="subtitle2"
                                        component="h4"
                                      >
                                        Upload files here
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        component="h4"
                                        className={classes.PageDescription}
                                      >
                                        Files supported: JPG, PNG, JPEG.
                                      </Typography>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          this.refs["file-upload-reg"].click();
                                        }}
                                        className={classes.activeGoButton}
                                      >
                                        Choose File
                                      </Button>
                                    </>
                                  )}
                                  <input
                                    ref={"file-upload-reg"}
                                    type="file"
                                    style={{
                                      display: "none",
                                    }}
                                    onChange={this.uploadRegsitration}
                                  />
                                </Paper>
                              </Paper>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className={classes.actionContainer}>
                    <Button
                      size="large"
                      color="default"
                      variant="contained"
                      className={classes.customMargin}
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      className={classNames(
                        classes.customMargin,
                        classes.activeGoButton
                      )}
                      onClick={this.saveHandler}
                    >
                      Register
                    </Button>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }

  // renderPlace = () => {

  //   return( <Autocomplete address={this.state.driverDetails.address} onPlaceChanged={this.showPlaceDetails.bind(this)} />) //basically you can do all sorts of the formatting and others
  // }
}

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUpc: (payload) => dispatch(searchUpcPublic(payload)),
    getAuthOperationalParameter: () => dispatch(getAuthOperationalParameter()),
    addDriver: (payload) => dispatch(registerDriver(payload)),
    driverCreatedDone: () => dispatch(driverCreatedDone()),
    showSnackbar: (message, variant) =>
      dispatch(showSnackbar(message, variant)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    (theme) => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme),
    }),
    { withTheme: true }
  )(DriverReferralSignUp)
);
