////////////
// Common //
////////////
export const RECEIVE_ERROR = "RECEIVE_ERROR";

//////////
// Login //
//////////
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const RECEIVE_USER = "RECEIVE_USER";

/////////////////////
// Forgot Password //
/////////////////////
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";


export const OTP_SENT = "OTP_SENT";

export const RECEIVE_OTP = "RECEIVE_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

///////////////////
// Home //
///////////////////
export const GET_DASHBOARD_DETAILS = "GET_DASHBOARD_DETAILS";
export const GET_DASHBOARD_DETAILS_SUCCESS = "GET_DASHBOARD_DETAILS_SUCCESS";
export const GET_DASHBOARD_DETAILS_FAIL = "GET_DASHBOARD_DETAILS_FAIL";

////////////
// Logout //
////////////
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_CLIENT_SUCCESS";

export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const SESSION_LOGOUT_SUCCESS = "SESSION_LOGOUT_SUCCESS";
export const SESSION_LOGOUT_FAIL = "SESSION_LOGOUT_FAIL";

////////////
// Profile //
////////////
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

//////////////
// LITERALS //
//////////////
export const LOAD_LITERALS = "LOAD_LITERALS";

///////////////
// ADMIN  //
///////////////

export const FETCH = "FETCH";
export const RECEIVE = "RECEIVE";

//////////////
// RIDER //
//////////////

export const ADD_RIDER = "ADD_RIDER";
export const ADD_RIDER_SUCCESS = "ADD_RIDER_SUCCESS";
export const ADD_RIDER_FAIL = "ADD_RIDER_FAIL";
export const RESET_RIDER_PASSWORD = "RESET_RIDER_PASSWORD";

export const ADD_UPC = "ADD_UPC";
export const ADD_UPC_SUCCESS = "ADD_UPC_SUCCESS";
export const ADD_UPC_FAIL = "ADD_UPC_FAIL";

export const ADD_UPC_CREDIT = "ADD_UPC_CREDIT";
export const ADD_UPC_CREDIT_SUCCESS = "ADD_UPC_CREDIT_SUCCESS";
export const ADD_UPC_CREDIT_FAIL = "ADD_UPC_CREDIT_FAIL";

export const EDIT_RIDER = "EDIT_RIDER";
export const EDIT_RIDER_SUCCESS = "EDIT_RIDER_SUCCESS";
export const EDIT_RIDER_FAIL = "EDIT_RIDER_FAIL";

export const CHANGE_RIDER_STATUS = "CHANGE_RIDER_STATUS";
export const CHANGE_RIDER_STATUS_SUCCESS = "CHANGE_RIDER_STATUS_SUCCESS";
export const CHANGE_RIDER_STATUS_FAIL = "CHANGE_RIDER_STATUS_FAIL";

export const FETCH_RIDERS = "FETCH_RIDERS";
export const FETCH_RIDERS_SUCCESS = "FETCH_RIDERS_SUCCESS";
export const FETCH_RIDERS_FAIL = "FETCH_RIDERS_FAIL";

export const FETCH_UPC = "FETCH_UPC";
export const FETCH_UPC_SUCCESS = "FETCH_UPC_SUCCESS";
export const FETCH_UPC_FAIL = "FETCH_UPC_FAIL";

export const FETCH_RIDER = "FETCH_RIDER";
export const FETCH_RIDER_SUCCESS = "FETCH_RIDER_SUCCESS";
export const FETCH_RIDER_FAIL = "FETCH_RIDER_FAIL";

export const FETCH_RIDER_CARD_LIST = "FETCH_RIDER_CARD_LIST";
export const FETCH_RIDER_CARD_LIST_SUCCESS = "FETCH_RIDER_CARD_LIST_SUCCESS";
export const FETCH_RIDER_CARD_LIST_FAIL = "FETCH_RIDER_CARD_LIST_FAIL";

export const SET_PLOT_COORDINATE = "SET_PLOT_COORDINATE";
export const RIDER_CREATED_DONE = "RIDER_CREATED_DONE";
export const RIDER_UPDATED_DONE = "RIDER_UPDATED_DONE";
export const RIDER_STATUS_UPDATED_DONE = "RIDER_STATUS_UPDATED_DONE";
export const UPC_UPDATED_DONE = "UPC_UPDATED_DONE";
export const RESET_DETAILS = "RESET_DETAILS";

/////////////////////
//Global config ////
///////////////////
export const GET_GLOBAL_CONFIG = "GET_GLOBAL_CONFIG";
export const GET_GLOBAL_CONFIG_SUCCESS = "GET_GLOBAL_CONFIG_SUCCESS";
export const GET_GLOBAL_CONFIG_FAIL = "GET_GLOBAL_CONFIG_FAIL";

export const UPDATE_GLOBAL_CONFIG = "UPDATE_GLOBAL_CONFIG";
export const UPDATE_GLOBAL_CONFIG_SUCCESS = "UPDATE_GLOBAL_CONFIG_SUCCESS";
export const UPDATE_GLOBAL_CONFIG_FAIL = "UPDATE_GLOBAL_CONFIG_FAIL";
export const CONFIG_UPDATED_DONE = "CONFIG_UPDATED_DONE";
export const RESET_CONFIG_DETAILS = "RESET_CONFIG_DETAILS";

///////////////////
////Rate Chart////
/////////////////
export const GET_RATE_CHART = "GET_RATE_CHART";
export const GET_RATE_CHART_SUCCESS = "GET_RATE_CHART_SUCCESS";
export const GET_SAPPHIRE_RATE_CHART = "GET_SAPPHIRE_RATE_CHART";
export const GET_SAPPHIRE_RATE_CHART_SUCCESS = "GET_SAPPHIRE_RATE_CHART_SUCCESS";
export const GET_RATE_CHART_FAIL = "GET_RATE_CHART_FAIL";
export const GET_SAPPHIRE_RATE_CHART_FAIL = "GET_SAPPHIRE_RATE_CHART_FAIL";

export const UPDATE_FIXED_RATE_CHART = "UPDATE_FIXED_RATE_CHART";
export const UPDATE_FIXED_RATE_CHART_SUCCESS =
  "UPDATE_FIXED_RATE_CHART_SUCCESS";
export const UPDATE_SAPPHIRE_FIXED_RATE_CHART = "UPDATE_SAPPHIRE_FIXED_RATE_CHART";
export const UPDATE_SAPPHIRE_FIXED_RATE_CHART_SUCCESS = "UPDATE_SAPPHIRE_FIXED_RATE_CHART_SUCCESS";
export const UPDATE_FIXED_RATE_CHART_FAIL = "UPDATE_FIXED_RATE_CHART_FAIL";
export const UPDATE_SAPPHIRE_FIXED_RATE_CHART_FAIL = "UPDATE_SAPPHIRE_FIXED_RATE_CHART_FAIL";
export const FIXED_RATE_CHART_UPDATED_DONE = "FIXED_RATE_CHART_UPDATED_DONE";
export const FIXED_SAPPHIRE_RATE_CHART_UPDATED_DONE = "FIXED_SAPPHIRE_RATE_CHART_UPDATED_DONE";
export const UPDATE_DURATION_RATE_CHART = "UPDATE_DURATION_RATE_CHART";
export const UPDATE_SAPPHIRE_DURATION_RATE_CHART = "UPDATE_SAPPHIRE_DURATION_RATE_CHART";
export const UPDATE_DURATION_RATE_CHART_SUCCESS = "UPDATE_DURATION_RATE_CHART_SUCCESS";
export const UPDATE_SAPPHIRE_DURATION_RATE_CHART_SUCCESS = "UPDATE_SAPPHIRE_DURATION_RATE_CHART_SUCCESS";
export const UPDATE_DURATION_RATE_CHART_FAIL = "UPDATE_DURATION_RATE_CHART_FAIL";
export const UPDATE_SAPPHIRE_DURATION_RATE_CHART_FAIL = "UPDATE_SAPPHIRE_DURATION_RATE_CHART_FAIL";
export const DURATION_RATE_CHART_UPDATED_DONE = "DURATION_RATE_CHART_UPDATED_DONE";
export const DURATION_SAPPHIRE_RATE_CHART_UPDATED_DONE = "DURATION_SAPPHIRE_RATE_CHART_UPDATED_DONE";
export const RESET_RATE_CHART_DETAILS = "RESET_RATE_CHART_DETAILS";
export const RESET_SAPPHIRE_RATE_CHART_DETAILS = "RESET_SAPPHIRE_RATE_CHART_DETAILS";

///////////////////////////////
///Operational Parameter//////
/////////////////////////////
export const GET_AUTH_PARAMETER = "GET_AUTH_PARAMETER";
export const GET_AUTH_PARAMETER_SUCCESS = "GET_AUTH_PARAMETER_SUCCESS";
export const GET_AUTH_PARAMETER_FAIL = "GET_AUTH_PARAMETER_FAIL";

export const GET_PARAMETER = "GET_PARAMETER";
export const GET_PARAMETER_SUCCESS = "GET_PARAMETER_SUCCESS";
export const GET_PARAMETER_FAIL = "GET_PARAMETER_FAIL";

export const UPDATE_PARAMETER = "UPDATE_PARAMETER";
export const UPDATE_PARAMETER_SUCCESS = "UPDATE_PARAMETER_SUCCESS";
export const UPDATE_PARAMETER_FAIL = "UPDATE_PARAMETER_FAIL";
export const PARAMETER_UPDATED_DONE = "PARAMETER_UPDATED_DONE";
export const RESET_PARAMETER_DETAILS = "RESET_PARAMETER_DETAILS";

//////////////
///Zone//////
////////////
export const GET_ZONE = "GET_ZONE";
export const GET_ZONE_SUCCESS = "GET_ZONE_SUCCESS";
export const GET_ZONE_FAIL = "GET_ZONE_FAIL";

export const UPDATE_ZONE = "UPDATE_ZONE";
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS";
export const UPDATE_ZONE_FAIL = "UPDATE_ZONE_FAIL";
export const ZONE_UPDATED_DONE = "ZONE_UPDATED_DONE";
export const RESET_ZONE_DETAILS = "RESET_ZONE_DETAILS";

////////////////
// DRIVER /////
//////////////

export const ADD_DRIVER = "ADD_DRIVER";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_FAIL = "ADD_DRIVER_FAIL";

export const REGISTER_DRIVER = "REGISTER_DRIVER";
export const REGISTER_DRIVER_SUCCESS = "REGISTER_DRIVER_SUCCESS";
export const REGISTER_DRIVER_FAIL = "REGISTER_DRIVER_FAIL";

export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const FETCH_DRIVERS_SUCCESS = "FETCH_DRIVERS_SUCCESS";
export const FETCH_DRIVERS_FAIL = "FETCH_DRIVERS_FAIL";

export const CHANGE_DRIVER_STATUS = "CHANGE_DRIVER_STATUS";
export const CHANGE_DRIVER_STATUS_SUCCESS = "CHANGE_DRIVER_STATUS_SUCCESS";
export const CHANGE_DRIVER_STATUS_FAIL = "CHANGE_DRIVER_STATUS_FAIL";

export const FETCH_DRIVER = "FETCH_DRIVER";
export const FETCH_DRIVER_SUCCESS = "FETCH_DRIVER_SUCCESS";
export const FETCH_DRIVER_FAIL = "FETCH_DRIVER_FAIL";

export const EDIT_DRIVER = "EDIT_DRIVER";
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS";
export const EDIT_DRIVER_FAIL = "EDIT_DRIVER_FAIL";

export const RESET_DRIVER_PASSWORD = "RESET_DRIVER_PASSWORD";
export const RESET_DRIVER_PASSWORD_SUCCESS = "RESET_DRIVER_PASSWORD_SUCCESS";
export const RESET_DRIVER_PASSWORD_FAIL = "RESET_DRIVER_PASSWORD_FAIL";


export const RESET_DRIVER_PASSWORD_DONE = "RESET_DRIVER_PASSWORD_DONE";

export const DRIVER_CREATED_DONE = "DRIVER_CREATED_DONE";
export const DRIVER_STATUS_UPDATED_DONE = "DRIVER_STATUS_UPDATED_DONE";
export const DRIVER_UPDATED_DONE = "DRIVER_UPDATED_DONE";
export const RESET_DRIVER_DETAILS = "RESET_DRIVER_DETAILS";

/////////////
// ROLE ////
///////////

export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL";

export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAIL = "FETCH_ROLES_FAIL";

export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAIL = "FETCH_ROLE_FAIL";

export const EDIT_ROLE = "EDIT_ROLE";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAIL = "EDIT_ROLE_FAIL";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

export const ROLE_CREATED_DONE = "ROLE_CREATED_DONE";
export const ROLE_STATUS_UPDATED_DONE = "ROLE_STATUS_UPDATED_DONE";
export const ROLE_UPDATED_DONE = "ROLE_UPDATED_DONE";
export const ROLE_DELETE_DONE = "ROLE_DELETE_DONE";
export const RESET_ROLE_DETAILS = "RESET_ROLE_DETAILS";

/////////////
// REVENUE ////
///////////

export const FETCH_PAYMENT_CONFIG = "FETCH_PAYMENT_CONFIG";
export const FETCH_PAYMENT_CONFIG_SUCCESS = "FETCH_PAYMENT_CONFIG_SUCCESS";
export const FETCH_PAYMENT_CONFIG_FAIL = "FETCH_PAYMENT_CONFIG_FAIL";

export const FETCH_CANCELLATION_FEE = "FETCH_CANCELLATION_FEE";
export const FETCH_CANCELLATION_FEE_SUCCESS = "FETCH_CANCELLATION_FEE_SUCCESS";
export const FETCH_CANCELLATION_FEE_FAIL = "FETCH_CANCELLATION_FEE_FAIL";

export const FETCH_GRAPH = "FETCH_GRAPH";
export const FETCH_GRAPH_SUCCESS = "FETCH_GRAPH_SUCCESS";
export const FETCH_GRAPH_FAIL = "FETCH_GRAPH_FAIL";

export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const EDIT_PAYMENT_SUCCESS = "EDIT_PAYMENT_SUCCESS";
export const EDIT_PAYMENT_FAIL = "EDIT_PAYMENT_FAIL";

export const EDIT_CANCELLATION_FEE = "EDIT_CANCELLATION_FEE";
export const EDIT_CANCELLATION_FEE_SUCCESS = "EDIT_CANCELLATION_FEE_SUCCESS";
export const EDIT_CANCELLATION_FEE_FAIL = "EDIT_CANCELLATION_FEE_FAIL";


export const PAYMENT_UPDATED_DONE = "PAYMENT_UPDATED_DONE";
export const EDIT_CANCELLATION_FEE_DONE = "EDIT_CANCELLATION_FEE_DONE";
export const RESET_PAYMENT = "RESET_PAYMENT";
export const RESET_CANCELLATION_FEE = "RESET_CANCELLATION_FEE";
export const RESET_GRAPH = "RESET_GRAPH";

/////////////
// ADMIN////
///////////

export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL";

export const FETCH_ADMINS = "FETCH_ADMINS";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAIL = "FETCH_ADMINS_FAIL";

export const FETCH_ADMIN = "FETCH_ADMIN";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAIL = "FETCH_ADMIN_FAIL";

export const EDIT_ADMIN = "EDIT_ADMIN";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAIL = "EDIT_ADMIN_FAIL";

export const CHANGE_STATUS_ADMIN = "CHANGE_STATUS_ADMIN";
export const CHANGE_STATUS_ADMIN_SUCCESS = "CHANGE_STATUS_ADMIN_SUCCESS";
export const CHANGE_STATUS_ADMIN_FAIL = "CHANGE_STATUS_ADMIN_FAIL";

export const ADMIN_CREATED_DONE = "ADMIN_CREATED_DONE";
export const ADMIN_STATUS_UPDATED_DONE = "ADMIN_STATUS_UPDATED_DONE";
export const ADMIN_UPDATED_DONE = "ADMIN_UPDATED_DONE";
export const ADMIN_DELETE_DONE = "ADMIN_DELETE_DONE";
export const RESET_ADMIN_DETAILS = "RESET_ADMIN_DETAILS";

//////////////////
// UPC RIDER ////
////////////////
export const FETCH_UPC_RIDERS = "FETCH_UPC_RIDERS";
export const FETCH_UPC_RIDERS_SUCCESS = "FETCH_UPC_RIDERS_SUCCESS";
export const FETCH_UPC_RIDERS_FAIL = "FETCH_UPC_RIDERS_FAIL";

export const FETCH_UPC_RIDER = "FETCH_UPC_RIDER";
export const FETCH_UPC_RIDER_SUCCESS = "FETCH_UPC_RIDER_SUCCESS";
export const FETCH_UPC_RIDER_FAIL = "FETCH_UPC_RIDER_FAIL";

//////////////////
// UPC DRIVER ////
//////////////////
export const FETCH_UPC_DRIVERS = "FETCH_UPC_DRIVERS";
export const FETCH_UPC_DRIVERS_SUCCESS = "FETCH_UPC_DRIVERS_SUCCESS";
export const FETCH_UPC_DRIVERS_FAIL = "FETCH_UPC_DRIVERS_FAIL";

export const FETCH_UPC_DRIVER = "FETCH_UPC_DRIVER";
export const FETCH_UPC_DRIVER_SUCCESS = "FETCH_UPC_DRIVER_SUCCESS";
export const FETCH_UPC_DRIVER_FAIL = "FETCH_UPC_DRIVER_FAIL";

/////////////////
// Past Trip ////
/////////////////

export const FETCH_PAST_TRIPS = "FETCH_PAST_TRIPS";
export const FETCH_PAST_TRIPS_SUCCESS = "FETCH_PAST_TRIPS_SUCCESS";
export const FETCH_PAST_TRIPS_FAIL = "FETCH_PAST_TRIPS_FAIL";

export const FETCH_PAST_TRIP = "FETCH_PAST_TRIP";
export const FETCH_PAST_TRIP_SUCCESS = "FETCH_PAST_TRIP_SUCCESS";
export const FETCH_PAST_TRIP_FAIL = "FETCH_PAST_TRIP_FAIL";
export const RESET_PAST_TRIP_DETAILS = "RESET_PAST_TRIP_DETAILS";

export const UPDATE_SCHEDULE_TIME = "UPDATE_SCHEDULE_TIME";
export const UPDATE_SCHEDULE_TIME_SUCCESS = "UPDATE_SCHEDULE_TIME_SUCCESS";
export const UPDATE_SCHEDULE_TIME_FAIL = "UPDATE_SCHEDULE_TIME_FAIL";
export const UPDATE_SCHEDULE_TIME_DONE = "UPDATE_SCHEDULE_TIME_DONE";

////////////////////
// Ongoing Trip ////
////////////////////

export const FETCH_ONGOING_TRIPS = "FETCH_ONGOING_TRIPS";
export const FETCH_ONGOING_TRIPS_SUCCESS = "FETCH_ONGOING_TRIPS_SUCCESS";
export const FETCH_ONGOING_TRIPS_FAIL = "FETCH_ONGOING_TRIPS_FAIL";

export const APPROVE_NO_SHOW = "APPROVE_NO_SHOW";
export const APPROVE_NO_SHOW_SUCCESS = "APPROVE_NO_SHOW_SUCCESS";
export const APPROVE_NO_SHOW_FAIL = "APPROVE_NO_SHOW_FAIL";

export const REJECT_NO_SHOW = "REJECT_NO_SHOW";
export const REJECT_NO_SHOW_SUCCESS = "REJECT_NO_SHOW_SUCCESS";
export const REJECT_NO_SHOW_FAIL = "REJECT_NO_SHOW_FAIL";

export const END_TRIP = "END_TRIP";
export const END_TRIP_SUCCESS = "END_TRIP_SUCCESS";
export const END_TRIP_FAIL = "END_TRIP_FAIL";

export const APPROVE_ADDITIONAL_CHARGE = "APPROVE_ADDITIONAL_CHARGE";
export const APPROVE_ADDITIONAL_CHARGE_SUCCESS =
  "APPROVE_ADDITIONAL_CHARGE_SUCCESS";
export const APPROVE_ADDITIONAL_CHARGE_FAIL = "APPROVE_ADDITIONAL_CHARGE_FAIL";

export const REJECT_ADDITIONAL_CHARGE = "REJECT_ADDITIONAL_CHARGE";
export const REJECT_ADDITIONAL_CHARGE_SUCCESS =
  "REJECT_ADDITIONAL_CHARGE_SUCCESS";
export const REJECT_ADDITIONAL_CHARGE_FAIL = "REJECT_ADDITIONAL_CHARGE_FAIL";

export const NO_SHOW_REJECTED_DONE = "NO_SHOW_REJECTED_DONE";
export const NO_SHOW_APPROVED_DONE = "NO_SHOW_APPROVED_DONE";
export const END_TRIP_DONE = "END_TRIP_DONE";
export const ADDITIONAL_CHARGE_APPROVED_DONE =
  "ADDITIONAL_CHARGE_APPROVED_DONE";
export const ADDITIONAL_CHARGE_REJECTED_DONE =
  "ADDITIONAL_CHARGE_REJECTED_DONE";

export const FETCH_ONGOING_TRIP = "FETCH_ONGOING_TRIP";
export const FETCH_ONGOING_TRIP_SUCCESS = "FETCH_ONGOING_TRIP_SUCCESS";
export const FETCH_ONGOING_TRIP_FAIL = "FETCH_ONGOING_TRIP_FAIL";
export const RESET_ONGOING_TRIP_DETAILS = "RESET_ONGOING_TRIP_DETAILS";

///////////////////////
// Booking Request ////
///////////////////////
export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
export const CREATE_BOOKING_REQUEST_SUCCESS = "CREATE_BOOKING_REQUEST_SUCCESS";
export const CREATE_BOOKING_REQUEST_FAIL = "CREATE_BOOKING_REQUEST_FAIL";
export const ASSIGN_DRIVERS_TO_BOOKING_REQUEST = "ASSIGN_DRIVERS_TO_BOOKING_REQUEST";
export const ASSIGN_DRIVERS_TO_BOOKING_REQUEST_SUCCESS = "ASSIGN_DRIVERS_TO_BOOKING_REQUEST_SUCCESS";

export const FETCH_BOOKING_REQUESTS = "FETCH_BOOKING_REQUESTS";
export const FETCH_BOOKING_REQUESTS_SUCCESS = "FETCH_BOOKING_REQUESTS_SUCCESS";
export const FETCH_BOOKING_REQUESTS_FAIL = "FETCH_BOOKING_REQUESTS_FAIL";

export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST";
export const CANCEL_BOOKING_REQUEST_SUCCESS = "CANCEL_BOOKING_REQUEST_SUCCESS";
export const CANCEL_BOOKING_REQUEST_FAIL = "CANCEL_BOOKING_REQUEST_FAIL";
export const BOOKING_CANCELED_DONE = "BOOKING_CANCELED_DONE";

export const FETCH_BOOKING_REQUEST = "FETCH_BOOKING_REQUEST";
export const FETCH_BOOKING_REQUEST_SUCCESS = "FETCH_BOOKING_REQUEST_SUCCESS";
export const FETCH_BOOKING_REQUEST_FAIL = "FETCH_BOOKING_REQUEST_FAIL";
export const RESET_BOOKING_REQUEST_DETAILS = "RESET_BOOKING_REQUEST_DETAILS";

//////////////////////////
// For Assign Driver ////
/////////////////////////

export const FETCH_TRIP = "FETCH_TRIP";
export const FETCH_TRIP_SUCCESS = "FETCH_TRIP_SUCCESS";
export const FETCH_TRIP_FAIL = "FETCH_TRIP_FAIL";
export const RESET_TRIP_DETAILS = "RESET_TRIP_DETAILS";

export const SEARCH_DRIVER = "SEARCH_DRIVER";
export const SEARCH_DRIVER_SUCCESS = "SEARCH_DRIVER_SUCCESS";
export const SEARCH_DRIVER_FAIL = "SEARCH_DRIVER_FAIL";

export const ASSIGN_DRIVER = "ASSIGN_DRIVER";
export const ASSIGN_DRIVER_SUCCESS = "ASSIGN_DRIVER_SUCCESS";
export const ASSIGN_DRIVER_FAIL = "ASSIGN_DRIVER_FAIL";
export const DRIVER_ASSIGNED_DONE = "DRIVER_ASSIGNED_DONE";

//////////////////////////
// Ongoing Trip Alert ////
//////////////////////////

export const FETCH_ONGOING_TRIP_ALERTS = "FETCH_ONGOING_TRIP_ALERTS";
export const FETCH_ONGOING_TRIP_ALERTS_SUCCESS =
  "FETCH_ONGOING_TRIP_ALERTS_SUCCESS";
export const FETCH_ONGOING_TRIP_ALERTS_FAIL = "FETCH_ONGOING_TRIP_ALERTS_FAIL";

//////////////////////
// Booking Alert ////
/////////////////////

export const FETCH_BOOKING_ALERTS = "FETCH_BOOKING_ALERTS";
export const FETCH_BOOKING_ALERTS_SUCCESS = "FETCH_BOOKING_ALERTS_SUCCESS";
export const FETCH_BOOKING_ALERTS_FAIL = "FETCH_BOOKING_ALERTS_FAIL";

////////////////////
// Notification ////
////////////////////

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAIL = "FETCH_NOTIFICATIONS_FAIL";

export const FETCH_NOTIFICATIONS_COUNT = "FETCH_NOTIFICATIONS_COUNT";
export const FETCH_NOTIFICATIONS_COUNT_SUCCESS =
  "FETCH_NOTIFICATIONS_COUNT_SUCCESS";
export const FETCH_NOTIFICATIONS_COUNT_FAIL = "FETCH_NOTIFICATIONS_COUNT_FAIL";

export const NOTIFICATIONS_SEEN = "NOTIFICATIONS_SEEN";
export const NOTIFICATIONS_SEEN_SUCCESS = "NOTIFICATIONS_SEEN_SUCCESS";
export const NOTIFICATIONS_SEEN_FAIL = "NOTIFICATIONS_SEEN_FAIL";

export const NOTIFICATIONS_SEEN_DONE = "NOTIFICATIONS_SEEN_DONE";

export const NOTIFICATIONS_MQTT_SUCEESS = "NOTIFICATIONS_MQTT_SUCEESS";

export const FETCH_NOTIFICATIONS_COUNT_DONE = "FETCH_NOTIFICATIONS_COUNT_DONE";

export const NOTIFICATIONS_MQTT_SUCEESS_DONE =
  "NOTIFICATIONS_MQTT_SUCEESS_DONE";


///////////////////
// Billing ////
/////////////////

export const FETCH_SETTLED_BILLS = "FETCH_SETTLED_BILLS";
export const FETCH_SETTLED_BILLS_SUCCESS = "FETCH_SETTLED_BILLS_SUCCESS";
export const FETCH_SETTLED_BILLS_FAIL = "FETCH_SETTLED_BILLS_FAIL";

export const FETCH_UNSETTLED_BILLS = "FETCH_UNSETTLED_BILLS";
export const FETCH_UNSETTLED_BILLS_SUCCESS = "FETCH_UNSETTLED_BILLS_SUCCESS";
export const FETCH_UNSETTLED_BILLS_FAIL = "FETCH_UNSETTLED_BILLS_FAIL";

export const FETCH_BILLING = "FETCH_BILLING";
export const FETCH_BILLING_SUCCESS = "FETCH_BILLING_SUCCESS";
export const FETCH_BILLING_FAIL = "FETCH_BILLING_FAIL";

export const SETTLE_BILL = "SETTLE_BILL";
export const SETTLE_BILL_SUCCESS = "SETTLE_BILL_SUCCESS";
export const SETTLE_BILL_FAIL = "SETTLE_BILL_FAIL";

export const SETTLE_TRANSFER_BILL = "SETTLE_TRANSFER_BILL";
export const SETTLE_TRANSFER_BILL_SUCCESS = "SETTLE_TRANSFER_BILL_SUCCESS";
export const SETTLE_TRANSFER_BILL_FAIL = "SETTLE_TRANSFER_BILL_FAIL";

export const EDIT_PAYMENT_BILL = "EDIT_PAYMENT_BILL";
export const EDIT_PAYMENT_BILL_SUCCESS = "EDIT_PAYMENT_BILL_SUCCESS";
export const EDIT_PAYMENT_BILL_FAIL = "EDIT_PAYMENT_BILL_FAIL";

export const BILL_UPDATED_DONE = "BILL_UPDATED_DONE";
export const RESET_BILLING_DETAILS = "RESET_BILLING_DETAILS";
export const RESET_SETTLED_BILL_DETAILS = "RESET_SETTLED_BILL_DETAILS";
export const RESET_UNSETTLED_BILL_DETAILS = "RESET_UNSETTLED_BILL_DETAILS";


///////////////////
// Snackbar ////
/////////////////
export const SNACKBAR_OPEN = "SNACKBAR_OPEN";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";
