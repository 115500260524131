import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./../../app/theme";
import BlankProfileImage from "../../assets/images/default-profile-pic.png";
import contentStyles from "../auth-layout/content.styles";
import styles from "./styles";
import globalstyles from "../../globalstyles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollArea from "react-scrollbar";
import { showSnackbar } from "../../store/actions/snackbar";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import {
  searchUpc,
  addRider,
  riderCreatedDone,
  resetDetails,
  registerRider,
  searchUpcPublic,
} from "../../store/actions/rider";
import CountryCodesConfig from "../../config/country-codes";

library.add(faUpload);

const countries = CountryCodesConfig.filter((each) => each.code === "US");

const userType = {
  1: "Chauffeur",
  2: "Rider",
};

class RiderReferralSignup extends Component {
  state = {
    single: null,
    labelWidth: 0,
    displaySuggestion: false,
    upcSuggestions: [],
    selectedUpc: {},
    fetchSuggestions: false,
    riderDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phoneCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
      phoneNumber: "",
      upcCode: "",
      profilePicture: "",
    },
    errors: {},
    filterData: {
      name: "",
      email: "",
      contactNumber: "",
      displayId: "",
    },
    haveImage: false,
  };

  handleChange = (event) => {
    let fields = { ...this.state.riderDetails };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "phoneNumber") {
      errors.invalidPhoneNumber = false;
    }

    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    if (event.target.name === "upcCode") {
      this.searchUPC(event.target.value);
    }

    this.setState({ ...this.state, riderDetails: fields, errors });
  };

  searchUPC = (searchData) => {
    this.props.searchUpc(searchData);
    // if (searchData.trim().length) {
    this.setState({
      ...this.state,
      displaySuggestion: true,
      fetchSuggestions: false,
    });
    // }
  };

  riderInfoValidation = () => {
    let fields = { ...this.state.riderDetails };
    let localUpc = { ...this.state.selectedUpc };
    let errors = {
      ...this.state.errors,
    };
    let hasErrors = false;

    if (!fields.firstName.trim()) {
      errors.firstName = true;
      hasErrors = true;
    }
    if (!fields.lastName.trim()) {
      errors.lastName = true;
      hasErrors = true;
    }
    if (!fields.phoneNumber.trim()) {
      errors.phoneNumber = true;
      hasErrors = true;
    }
    if (
      fields.phoneNumber.trim().length &&
      fields.phoneNumber.trim().length < 10
    ) {
      errors.invalidPhoneNumber = true;
      hasErrors = true;
    }

    if (!fields.email.trim()) {
      errors.email = true;
      hasErrors = true;
    }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);
    console.log("fields ", fields);

    if (!fields.upcCode.trim().length) {
      localUpc = {};
    }

    this.setState({ errors: errors, selectedUpc: localUpc });
    return hasErrors;
  };

  searchUPCPublic = (code, userId) => {
    this.props
      .searchUpc(code, userId)
      .then((response) => {
        console.log("API Response:", response);

        if (response.payload.data.length > 0) {
          const referrer = response.payload.data[0];
          this.setState((prev) => ({
            ...prev,
            selectedUpc: {
              id: referrer._id,
              userType: referrer.userType,
            },
          }));
        } else {
          showSnackbar("Invalid UPC code", "error");
        }
      })
      .catch((error) => {
        console.error("Error verifying UPC code:", error);
        showSnackbar("Failed to verify UPC code", "error");
      });
  };

  verifyUpcCode = async (code) => {
    this.searchUPCPublic(code, this.state.riderDetails.id);
  };

  componentDidMount() {
    this.setState({
      ...this.state,
    });
    // Get UPC code from URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      this.setState(
        (prevState) => ({
          riderDetails: {
            ...prevState.riderDetails,
            upcCode: code,
          },
        }),
        () => {
          // After state update, call the verifyUpcCode method
          this.verifyUpcCode(code);
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (ReactDOM.findDOMNode(this.InputLabelRef)) {
      this.setState({
        ...this.state,
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
    }
    if (
      this.props.data.riderReducer.upcData.length &&
      !this.state.fetchSuggestions
    ) {
      this.setState({
        ...this.state,
        upcSuggestions: this.props.data.riderReducer.upcData,
        fetchSuggestions: true,
        displaySuggestion: true,
      });
    }
    if (
      !this.props.data.riderReducer.upcData.length &&
      this.state.fetchSuggestions
    ) {
      this.setState({
        ...this.state,
        upcSuggestions: this.props.data.riderReducer.upcData,
        fetchSuggestions: false,
        displaySuggestion: true,
      });
    }

    if (this.props.data.riderReducer.riderCreated) {
      this.props.riderCreatedDone();
      window.location.href = "https://www.urpc.us";
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  saveHandler = () => {
    if (!this.riderInfoValidation()) {
      let requestData = { ...this.state.riderDetails };
      requestData.phoneCountryCode =
        requestData.phoneCountryCode.split(" (")[0];
      console.log("this.state.selectedUpc ", this.state.selectedUpc);
      if (requestData.upcCode.trim().length) {
        if (
          !Object.keys(this.state.selectedUpc).length ||
          (Object.keys(this.state.selectedUpc).length &&
            this.state.selectedUpc.upcBucketInfo.upcCode !==
              requestData.upcCode)
        ) {
          this.props.showSnackbar("Invalid UPC code", "error");
          return;
        }
      }

      if (Object.keys(this.state.selectedUpc).length) {
        requestData.upcCodeReferredBy = this.state.selectedUpc._id;
        requestData.upcCodeReferredByType = this.state.selectedUpc.userType;
      }
      if (!requestData.upcCode.trim().length) {
        delete requestData.upcCodeReferredBy;
        delete requestData.upcCodeReferredByType;
        delete requestData.upcCode;
      }
      // console.log("requestData ", requestData);
      let formData = new FormData();
      for (let name in requestData) {
        formData.append(name, requestData[name]);
      }
      // return;
      this.props.addRider(formData);
    }
  };

  uploadLogo = (e) => {
    if (
      e.target.files &&
      e.target.files.length &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpg" &&
      e.target.files[0].type !== "image/jpeg"
    ) {
      this.props.showSnackbar("Profile image must be of jpg/png", "error");
      return;
    }

    let localRiderData = { ...this.state.riderDetails };
    localRiderData.profilePicture = e.target.files[0];
    this.setState({ ...this.state, riderDetails: localRiderData });
  };

  selectUpc = (suggestion) => {
    let localRiderData = { ...this.state.riderDetails };
    localRiderData.upcCode = suggestion.upcBucketInfo.upcCode;
    this.setState({
      ...this.state,
      selectedUpc: { ...suggestion },
      displaySuggestion: false,
      riderDetails: localRiderData,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Grid container>
          <Grid item style={{ display:'flex', flexDirection: 'column', margin: '0 auto' }}>
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              <Link to="/login">
                <img src={Logo} alt="logo" style={{height: 64}}/>
              </Link>
            </Typography>
            <Typography component="p" style={{ textAlign: "center", paddingLeft: 16 }}>
              <span className={classes.pageHeader}>
                {this.props.data.literalsReducer.Register.Rider}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Paper style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} className={classes.rightPaper}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} className={classes.riderLeft}>
                        <div className={classes.profileImagePlaceholder}>
                          {this.state.riderDetails.profilePicture ? (
                            typeof this.state.riderDetails.profilePicture ===
                            "object" ? (
                              <div
                                style={{
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url( ${window.URL.createObjectURL(
                                    this.state.riderDetails.profilePicture
                                  )} )`,
                                }}
                              />
                            ) : (
                              <img
                                src={this.state.riderDetails.profilePicture}
                                alt="image"
                                style={{ width: "100%" }}
                              />
                            )
                          ) : (
                            <img
                              src={BlankProfileImage}
                              alt="no-menu-image"
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                        <div className={classes.profileImageUploaderContainer}>
                          <div
                            className={classes.uploadImageButtonFile}
                            onClick={(e) => {
                              this.refs["file-upload"].click();
                            }}
                          >
                            <input
                              ref={"file-upload"}
                              type="file"
                              style={{
                                display: "none",
                              }}
                              onChange={this.uploadLogo}
                            />
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ fontSize: "30px", color: "#fff" }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={16}>
                          <Grid item xs={12} sm={6}>
                            <p className={classes.description}>
                              {this.props.data.literalsReducer.AddRider.Name}
                            </p>
                            <Grid container spacing={16}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="rider-first-name"
                                  label={
                                    this.props.data.literalsReducer.AddRider
                                      .FirstName
                                  }
                                  variant="outlined"
                                  className={classNames(
                                    classes.textField,
                                    classes.halfField
                                  )}
                                  name="firstName"
                                  value={this.state.riderDetails.firstName}
                                  onChange={this.handleChange}
                                  error={this.state.errors.firstName}
                                />
                                {this.state.errors.firstName ? (
                                  <FormHelperText id="validation-error2">
                                    {
                                      this.props.data.literalsReducer
                                        .ValidationErrors.Required
                                    }
                                  </FormHelperText>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="rider-last-name"
                                  label={
                                    this.props.data.literalsReducer.AddRider
                                      .LastName
                                  }
                                  variant="outlined"
                                  className={classNames(
                                    classes.textField,
                                    classes.halfField
                                  )}
                                  name="lastName"
                                  value={this.state.riderDetails.lastName || ""}
                                  onChange={this.handleChange}
                                  error={this.state.errors.lastName}
                                />
                                {this.state.errors.lastName ? (
                                  <FormHelperText id="validation-error2">
                                    {
                                      this.props.data.literalsReducer
                                        .ValidationErrors.Required
                                    }
                                  </FormHelperText>
                                ) : null}
                              </Grid>
                            </Grid>
                            <p className={classes.description}>
                              {this.props.data.literalsReducer.AddRider.PhoneNo}
                            </p>
                            <div className={classes.inlineField}>
                              <TextField
                                id="select-country"
                                select
                                name="phoneCountryCode"
                                value={this.state.riderDetails.phoneCountryCode}
                                onChange={this.handleChange}
                                variant="outlined"
                                className={classNames(
                                  classes.textField,
                                  classes.countrySelector
                                )}
                                SelectProps={{
                                  native: true,
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                              >
                                {countries.map((option, index) => (
                                  <option
                                    key={index}
                                    value={`${option.code} (${option.dial_code})`}
                                  >
                                    {`${option.code} (${option.dial_code})`}
                                  </option>
                                ))}
                              </TextField>
                              <TextField
                                id="rider-phone-no"
                                label={
                                  this.props.data.literalsReducer.AddRider
                                    .PhoneNo
                                }
                                variant="outlined"
                                fullWidth
                                className={classNames(
                                  classes.textField,
                                  classes.phoneNumber
                                )}
                                name="phoneNumber"
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                inputProps={{
                                  minLength: 10,
                                  maxLength: 10,
                                }}
                                value={this.state.riderDetails.phoneNumber}
                                onChange={this.handleChange}
                                error={
                                  this.state.errors.phoneNumber ||
                                  this.state.errors.invalidPhoneNumber
                                }
                              />
                              {this.state.errors.phoneNumber ? (
                                <FormHelperText
                                  id="validation-error2"
                                  style={{ marginLeft: "33%" }}
                                >
                                  {
                                    this.props.data.literalsReducer
                                      .ValidationErrors.Required
                                  }
                                </FormHelperText>
                              ) : null}
                              {this.state.errors.invalidPhoneNumber &&
                              !this.state.errors.phoneNumber ? (
                                <FormHelperText
                                  id="validation-error2"
                                  style={{ marginLeft: "33%" }}
                                >
                                  {
                                    this.props.data.literalsReducer
                                      .ValidationErrors.InvalidPhoneNumber
                                  }
                                </FormHelperText>
                              ) : null}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <p className={classes.description}>
                              {this.props.data.literalsReducer.AddRider.Email}
                            </p>
                            <TextField
                              id="rider-email"
                              label={
                                this.props.data.literalsReducer.AddRider.Email
                              }
                              variant="outlined"
                              fullWidth
                              type="email"
                              className={classes.textField}
                              name="email"
                              onChange={this.handleChange}
                              value={this.state.riderDetails.email}
                              error={
                                this.state.errors.email ||
                                this.state.errors.emailInvalid
                              }
                            />
                            {this.state.errors.email ? (
                              <FormHelperText id="validation-error2">
                                {
                                  this.props.data.literalsReducer
                                    .ValidationErrors.Required
                                }
                              </FormHelperText>
                            ) : null}
                            {!this.state.errors.email &&
                            this.state.errors.emailInvalid ? (
                              <FormHelperText id="validation-error">
                                {
                                  this.props.data.literalsReducer
                                    .ValidationErrors.InvalidEmail
                                }
                              </FormHelperText>
                            ) : null}
                            <p className={classes.description}>
                              {
                                this.props.data.literalsReducer.AddRider
                                  .AttachUpcCode
                              }
                            </p>
                            <TextField
                              id="rider-upc-code"
                              label={
                                this.props.data.literalsReducer.AddRider
                                  .AttachUpcCode
                              }
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                              name="upcCode"
                              value={this.state.riderDetails.upcCode || ""}
                              onChange={this.handleChange}
                            />
                            {this.props.data.riderReducer.upcData.length &&
                            this.state.displaySuggestion ? (
                              <div className={classes.suggestionList}>
                                <ScrollArea
                                  speed={0.8}
                                  className={classes.scrollContent}
                                  contentClassName="content"
                                  horizontal={false}
                                  style={{ minHeight: "110px" }}
                                >
                                  {this.props.data.riderReducer.upcData.map(
                                    (suggestion, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={classes.suggestionItem}
                                          onClick={() =>
                                            this.selectUpc(suggestion)
                                          }
                                        >
                                          {suggestion.personalInfo.fullName} (
                                          {userType[suggestion.userType]}) -{" "}
                                          {suggestion.upcBucketInfo.upcCode}
                                        </div>
                                      );
                                    }
                                  )}
                                </ScrollArea>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className={classes.actionContainer}>
                    <Button
                      size="large"
                      color="default"
                      variant="contained"
                      className={classes.customMargin}
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      className={classNames(
                        classes.customMargin,
                        classes.activeGoButton
                      )}
                      onClick={this.saveHandler}
                    >
                      Register
                    </Button>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUpc: (payload) => dispatch(searchUpcPublic(payload)),
    addRider: (payload) => dispatch(registerRider(payload)),
    riderCreatedDone: () => dispatch(riderCreatedDone()),
    resetDetails: () => dispatch(resetDetails()),
    showSnackbar: (message, variant) =>
      dispatch(showSnackbar(message, variant)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    (theme) => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme),
    }),
    { withTheme: true }
  )(RiderReferralSignup)
);
