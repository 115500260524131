import { baseUrl } from "./api.util";
import billing from "../../containers/auth/billing";
export const apiURLs = {
  //*****************Auth ************************* *//
  login: baseUrl + "admin/auth/login",
  logout: baseUrl + "account/admin/profile/logout",
  forgotPassword: baseUrl + "admin/auth/forgot-password/request-otp",
  resetPassword: baseUrl + "admin/auth/forgot-password/verify-otp",
  searchUpcPublic: baseUrl + "common/get-upc-code",
  riderRegister: baseUrl + "common/rider/register",
  driverRegister: baseUrl + "common/driver/register",
  authOperationalParameter: baseUrl + "common/driver/operational-parameter",

  //******************Profile************************//
  getProfile: baseUrl + "account/admin/profile",
  updateProfile: baseUrl + "account/admin/profile",
  changePassword: baseUrl + "account/admin/profile/change-password",
  //***************** Dashboard ***************//
  dashboardDetail: baseUrl + "account/admin/dashboard/home",
  //***************** Riders ***************//
  rider: baseUrl + "account/admin/rider/",
  searchUpc: baseUrl + "account/admin/common/get-upc-code",
  riderCardList: baseUrl + "account/admin/rider/cards/",
  riderAddCredit: (id) => baseUrl + "account/admin/rider/" + id + "/add-credit",
  //***************** Driver ***************//

  driver: baseUrl + "account/admin/driver/",

  //*********Role********** *//
  role: baseUrl + "account/admin/role/",

  //*********Admin********** *//
  admin: baseUrl + "account/admin/admin-user/",

  //*********Revenue********** *//
  revenue: baseUrl + "account/admin/revenue/",

  addUpc: baseUrl + "account/admin/upc-rider/code/assign",
  //***************** Global Settings ***************//
  globalConfig: baseUrl + "account/admin/global-config",
  //***************** Rate Chart ***************//
  rateChart: baseUrl + "account/admin/fare-chart",
  //***************** Operation Parameter ***************//
  operationalParameter: baseUrl + "account/admin/operational-parameter",
  //*********Zone********** *//
  zone: baseUrl + "account/admin/zone/",
  //*********UPC Rider********** *//
  upcRider: baseUrl + "account/admin/upc-rider/",
  //*********UPC Driver********** *//
  upcDriver: baseUrl + "account/admin/upc-driver/",
  //*********Past Trip********** *//
  pastTrip: baseUrl + "account/admin/trip/past",
  adjustScheduleTime: (id) => baseUrl + "account/admin/trip/" + id + "/update-pickup-time",
  //*********Ongoing Trip********** *//
  ongoingTrip: baseUrl + "account/admin/trip/ongoing",
  noShowAction: baseUrl + "account/admin/trip/no-show/",
  additionalChargeAction: baseUrl + "account/admin/trip/additional-charge/",
  endTrip: baseUrl + "account/admin/trip/end-trip/",
  //*********Ongoing Trip Alert********** *//
  ongoingTripAlert: baseUrl + "account/admin/trip/trip-alert",
  //*********Booking Alert********** *//
  bookingTripAlert: baseUrl + "account/admin/trip/booking-alert",
  //*********Booking Request********** *//
  bookingRequest: baseUrl + "account/admin/trip/booking-request",
  createBookingRequest: baseUrl + "account/admin/trip/rider/request/",
  assignDriversToBookingRequest: (id) => baseUrl + "account/admin/trip/" + id + "/rearrange-and-create-request",
  //*********Search Alternative Driver********** *//
  searchDriver: baseUrl + "account/admin/trip/fetch-available-driver",
  assignDriver: baseUrl + "account/admin/trip/assign-driver/",
  //*********Notification********** *//
  getNotifications: baseUrl + "account/admin/notification/list",
  getNotificationCount: baseUrl + "account/admin/notification/unread-count",
  updateNotification: baseUrl + "account/admin/notification/",

  //************Billing ****************//
  billing: baseUrl + "account/admin/trip/",
};
